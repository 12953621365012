import React from 'react';

import Row from '../Container/row';
import Col from '../Container/column';

import LoginForm from './form';
import LoginLinks from '../Account/links';

const loginLinks = [
  {
    label: 'Create Account',
    link: '/account/register/',
  },
  {
    label: 'Forgotten Password?',
    link: '/account/forgotten-password/',
  },
];

export default function Login() {
  return (
    <Row>
      <Col
        w={{ width: `35%` }}
        xxl={{ width: `40%` }}
        xl={{ width: `45%` }}
        lg={{ width: `50%` }}
        md={{ width: `65%` }}
      >
        <LoginForm />
        <LoginLinks data={loginLinks} />
      </Col>
    </Row>
  );
}
